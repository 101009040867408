import React, { useState } from 'react';
import type { FormEvent } from 'react';
import { signIn } from 'next-auth/react';
import { FormButton } from './FormButton';
import { ParentButton } from './ParentButton';
import { useTranslations } from 'next-intl';

function LoginForm({ onSuccess, isCTA }: { onSuccess: (email: string) => void; isCTA?: boolean }) {
  const [emailLoginRequested, setEmailLoginRequested] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const t = useTranslations('LogIn.Component');

  async function handleEmailLogin(e: FormEvent) {
    e.preventDefault();
    setEmailLoginRequested(true);
    const response = await signIn('email', {
      email: email,
      redirect: false,
    });
    onSuccess(email);
  }

  return (
    <>
      <form className="flex p-4 flex-col place-content-center gap-2" onSubmit={handleEmailLogin}>
        <div className="inline-flex text-sm font-medium -mb-1">{t('Email')}</div>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="example@email.com"
          required
          className="inline-flex w-52 p-2 border-2 text-md bg-old-lace border-black rounded-tr-3xl rounded-bl-3xl rounded-tl-sm rounded-br-sm focus:outline-none  focus:border-goldenrod"
        />

        {isCTA ? (
          <div className="inline-flex">
            <ParentButton
              text={t('SignUp')}
              type="submit"
              isLoading={emailLoginRequested}
              disabled={emailLoginRequested}
            />
          </div>
        ) : (
          <div className="place-self-end">
            <FormButton
              text={t('LogIn')}
              type="submit"
              isLoading={emailLoginRequested}
              disabled={emailLoginRequested}
            />
          </div>
        )}
      </form>
    </>
  );
}

export default LoginForm;
